.theme-provider {
  height: 100dvh; /* Fallback for browsers that do not support custom props */
  height: calc(var(--vh, 1dvh) * 100);
  display: flex;
  flex-direction: column;
  font-family: Inter;
  overflow: hidden;
}

.theme-provider.dark > * {
  --foundation-black-black-500: red;
}
