.gallery-body {
  /* padding: 3rem 4rem 4rem 4rem; */
}

.gallery-icon path {
  fill: var(--foundation-black-black-900, #080707);
}

.gallery-filter-container {
  display: flex;
  padding: 0.875rem 1.5rem 2rem 0rem;
  align-items: flex-start;
  gap: 4px;
}

.gallery-filter-dropdown {
  color: var(--foundation-black-black-500, #131010);
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
  display: flex;
  padding: 0rem 0.25rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.gallery-device-selector {
  width: fit-content;
  margin-bottom: 2rem;
}

.gallery-images {
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.gallery-btns {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
}

.gallery-btn-theme {
  background-color: var(--primary-green-500) !important;
}
.gallery-btn-theme:hover {
  background-color: var(--primary-green-700) !important;
}

.gallery-month {
  margin-top: 16px;
  color: var(--foundation-black-black-500, #131010);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
}

.gallery-images-image:hover {
  cursor: pointer;
}
