.charts-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}

.charts-offset-selector-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.charts-offset-selector-container .device-selector-container {
  align-self: stretch;
}

.charts-offset-selector-label {
  display: flex;
  padding: 0 0.25rem;
  align-items: flex-start;
  align-self: stretch;
  color: var(--foundation-black-black-500);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.30rem;
}