.walkthrough-container {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex: 1;
  padding: 1rem;
  background: var(--foundation-white-white-500);
  height: 100%;
  box-sizing: border-box;
}

.walkthrough-container-content {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 64rem;
  background: var(--foundation-white-white-50);
  box-shadow: 0px 69px 76.6px -57px rgba(0, 0, 0, 0.12);
  overflow: auto;
}

@media only screen and (min-width: 768px) {
  .walkthrough-container {
    padding: 1.5rem;
  }
}

@media only screen and (min-width: 1440px) {
  .walkthrough-container {
    padding: 9rem 13rem;
  }
}

@media only screen and (min-width: 1920px) {
  .walkthrough-container-content {
    max-width: 80rem;
  }
}
