.latest-photo-container {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.latest-photo-box {
  display: flex;
  width: 100%;
  height: 22.5rem;
  align-items: flex-start;
  align-content: flex-start;
  column-gap: 1rem;
  padding: 16px 0;
}

.latest-photo-left-wrapper {
  display: flex;
  height: 22.5rem;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.latest-photo-right-wrapper {
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}

.last-update {
  color: var(--Foundation-White-white-700, #aeaeae);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.last-update-bold {
  color: var(--Foundation-White-white-700, #aeaeae);
  text-align: start;
  font-size: 0.75rem;
  font-weight: 700;
  margin-bottom: 8px;
}

.latest-photo-text {
  color: var(--Foundation-Black-black-500, #131010);
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}

.latest-photo-big {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.latest-photo-big:hover {
  cursor: pointer;
}

.latest-photo-big-container {
  width: 100%;
  height: 100%;
}

.latest-photo-small {
  display: flex;
  height: 100%;
  width: 100%;
  min-width: 109.33px;
  max-width: 128px;
  min-height: 109.33px;
  max-height: 128px;
  justify-content: center;
  align-items: flex-end;
  flex: 1 0 0;
  object-fit: cover;
}

.latest-photo-small:hover {
  cursor: pointer;
}

.latest-photo-small-container {
  min-width: 109.33px;
  max-width: 128px;
  min-height: 109.33px;
  max-height: 128px;
}

.buttons {
  position: relative;
  display: flex;
  bottom: 60px;
  display: flex;
  color: white;
  justify-content: space-evenly;
}

.btn-download {
  color: var(--Foundation-White-white-100, #fcfcfc);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  padding: 16px 0px;
  width: 100%;
  line-height: 24px;
  background: rgba(35, 33, 33, 0.6);
  transition: 400ms;
}

.btn-download:hover {
  background: rgba(35, 33, 33, 1);
  cursor: pointer;
  transition: 400ms;
}

.info {
  color: var(--Foundation-White-white-100, #fcfcfc);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  padding: 16px 0px;
  width: 100%;
  background: rgba(35, 33, 33, 0.6);
}

.info-small {
  color: var(--Foundation-White-white-100, #fcfcfc);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  display: flex;
  padding: 6px 0px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  background: rgba(35, 33, 33, 0.6);
  position: relative;
  bottom: 2rem;
}

.full-gallery-btn {
  color: var(--Foundation-White-white-100, #fcfcfc);
  background: var(--primary-green-500);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  padding: 12px 16px;
  align-items: flex-start;
  gap: 8px;
  margin-top: 8px;
  width: fit-content;
  transition: 400ms;
}

.full-gallery-btn:hover {
  cursor: pointer;
  background: var(--primary-green-700);
  transition: 400ms;
}

.icon {
  height: 20px;
  width: 18px;
}
.preview {
  position: absolute;
  z-index: 9999;
  height: 100%;
  width: 100%;
  background-color: rgba(3, 3, 3, 0.9);
  top: 0;
  left: 0;
  overflow: hidden;
}

.preview-img {
  margin: auto;
  max-height: 90vh;
  max-width: 90vw;
  display: flex;
  object-fit: contain;
}

.close-btn {
  position: absolute;
  top: 0;
  right: 20px;
  color: white;
  scale: 1.5;
}

.close-btn:hover {
  cursor: pointer;
}

.preview-text {
  color: var(--Foundation-White-white-100, #fcfcfc);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.preview-box {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.control-arrow {
  visibility: hidden;
}

.image {
  height: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .latest-photo-container {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .latest-photo-container {
    width: 100%;
  }

  .latest-photo-right-wrapper {
    display: none;
  }
}
