.search-container {
  padding-bottom: 1rem;
}

.search-label {
  color: var(--Foundation-Black-black-500, #131010);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  padding: 0.5rem 0;
  display: flex;
  justify-content: flex-start;
}

.search-error-message {
  min-height: 1.25rem;
}

.search-checkboxes {
  display: flex;
  gap: 1rem;
}

.checkbox-label {
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  margin-left: 0.5rem;
}

.automation-buttons {
  display: flex;
  flex-wrap: wrap;
  padding-top: 1rem;
  padding-bottom: 2rem;
  gap: 2rem;
}

.automation-body .default-button {
  width: 25rem;
}

.automation-icon path {
  fill: black;
}

.automation-status {
  color: var(--Foundation-Black-black-500, #131010);
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  padding-top: 0.5rem;
  padding-bottom: 0.75rem;
}

.automation-table {
  text-align: left;
}

.automation-table .primary {
  font-weight: bold;
}

.automation-table .table-title {
  text-align: start;
}

.automation-table .secondary {
  color: var(--foundation-black-black-200);
}

.automation-table .hovered:hover .secondary {
  color: var(--foundation-white-white-500);
}

.automation-table .hovered:hover .primary {
  color: var(--foundation-white-white-500);
}

.automation-table .hovered:hover .edit-icon path {
  fill: var(--foundation-White-white-50, #fefefe);
}

.automation-table .edit-icon,
.automation-table .delete-icon {
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  height: 2rem;
  width: 2rem;
}

.automation-table .hovered:hover .delete-icon path {
  fill: var(--foundation-White-white-50, #fefefe);
}

.automation-table .hovered .delete-icon:hover {
  background-color: var(--foundation-red-red-400);
}

.automation-table .automation-name {
  text-align: left;
  overflow: hidden;
  min-width: 0;
  text-overflow: ellipsis;
}

.automation-table .device-name {
  flex: 1;
}

@media only screen and (max-width: 767px) {
  .plan-name-buttons .default-button {
    flex: 1;
  }

  .automation-buttons .default-button {
    flex: 1;
  }

  .automation-table .mobile-hidden {
    display: none;
  }

  .automation-table .automation-name {
    flex: 1;
  }
}

.no-break-text {
  white-space: nowrap;
}

.modal-delete-btn path {
  fill: #fff;
}

.modal-delete-btn,
.modal-close-btn,
.modal-close-btn-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-close-btn path {
  fill: #000;
}

.modal-close-btn-icon path {
  fill: #fff;
}

.modal-button-row {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@media only screen and (max-width: 600px) {
  .date-range-text {
    text-wrap: wrap;
    max-width: 100px;
    min-width: 100px;
  }
}
