.growing-plan-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin-bottom: 1rem;
}

.growing-plan-first-row {
  display: flex;
  column-gap: 1.5rem;
  align-items: flex-start;
}

.growing-plan-divider-line {
  border: none;
  color: var(--Foundation-Black-black-50, #e7e7e7);
  background-color: var(--Foundation-Black-black-50, #e7e7e7);
  height: 1px;
  width: 100%;
  margin-top: 0.8rem;
}

.growing-plan-first-row-title {
  color: var(--Foundation-Black-black-500, #131010);
  font-family: Inter;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.5rem;
  height: fit-content;
  min-width: fit-content;
}

.growing-plan-second-row {
  display: flex;
  column-gap: 1.5rem;
  flex-wrap: wrap;
  row-gap: 1rem;
  padding-top: 0.5rem;
}

.growing-plan-second-row-text-wrapper {
  display: flex;
}

.growing-plan-second-row-main-text,
.growing-plan-second-row-secondary-text {
  color: var(--Foundation-Black-black-500, #131010);
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  line-height: 1.5rem;
  white-space: nowrap;
}

.growing-plan-second-row-main-text {
  font-weight: 600;
}

.growing-plan-second-row-secondary-text {
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.growing-plan-second-row-secondary-text:hover {
  white-space: wrap;
  overflow: visible;
  max-width: fit-content;
}

.growing-plan-timeline-indicator {
  position: relative;
  width: 100%;
  height: 0.5rem;
  background: var(--foundation-white-white-600, #dfdfdf);
}

.growing-plan-timeline-progress-bar {
  position: absolute;
  height: 0.5rem;
  background-color: var(--primary-green-600, #21bb83);
  width: 100%;
  content: '';
  display: block;
  transition: width 0.7s ease-in-out;
}

.growing-plan-timeline-custom {
  position: relative;
  display: block;
  height: 0.6rem;
  background: var(--foundation-white-white-600, #dfdfdf);
  text-align: left;
  font-size: 0px;
  letter-spacing: 0px;
  word-spacing: 0px;
}

.growing-plan-timeline-custom .growing-plan-timeline-completed {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0%;
  background-color: var(--primary-green-600, #21bb83);
}

.growing-plan-timeline-custom .growing-plan-timeline-item {
  position: relative;
  display: inline-block;
  height: 100%;
  width: 1.2rem;
  box-sizing: border-box;
  border-right: 2px solid black;
}

.growing-plan-timeline-custom .growing-plan-timeline-item:last-child {
  border-right: none;
}
