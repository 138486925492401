.chart-container {
  position: relative;
  max-width: 1200px;
  margin: auto;
}

.title {
  color: var(--foundation-black-black-500);

  /* Subtitle 20px/Subtitle 2: Semi Bold */
  font-family: Inter;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 0.5rem 0rem;
}

.custom-button {
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--primary-green-500);
}

.chart-container .chart-container__no_result_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* background-color: rgba(0, 0, 0, 0.1); */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
}

@media only screen and (max-width: 767px) {
  .chart-container .chart-container__no_result_overlay {
    font-size: 1.6rem;
  }
}
