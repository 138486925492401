.gallery-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gallery-image-title {
  margin-top: 0.5rem;
  color: var(--foundation-black-black-200, #929191);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
  width: 9.5rem;
  text-wrap: nowrap;
}

.gallery-image {
  width: 9.5rem;
  height: 6.0625rem;
  object-fit: cover;
}

.gallery-carousel {
  width: 100%;
  height: 100%;
  background-color: var(--gallery-background);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 2rem; */
  padding-top: 2rem;
}

.gallery-carousel-image {
  height: 75%;
  max-width: 80%;
  object-fit: contain;
}

.gallery-image-preview {
  height: 85%;
  max-width: 80%;
  object-fit: contain;
}

.gallery-carousel-image-text {
  color: var(--foundation-white-white-50);
  margin: 1rem 0;
}

.gallery-carousel-thumbnail-active {
  padding: 0.25rem;
  background-color: var(--primary-green-500);
  max-height: 12%;
}

.gallery-carousel-thumbnail {
  padding: 0.25rem;
  background-color: var(--foundation-black-black-100);
  max-height: 12%;
  transition: 400ms;
}

.gallery-carousel-thumbnail:hover {
  padding: 0.25rem;
  background-color: var(--primary-green-500);
  cursor: pointer;
  max-height: 12%;
  transition: 400ms;
}

.thumbnails {
  gap: 1.5rem;
  display: flex;
}

.gallery-carousel-next {
  position: absolute;
  right: 1rem;
  top: 50%;
  fill: var(--foundation-white-white-50);
  transition: 400ms;
}

.gallery-carousel-next:hover {
  cursor: pointer;
  scale: 1.2;
  transition: 400ms;
}

.gallery-carousel-prev:hover {
  cursor: pointer;
  scale: 1.2;
  transition: 400ms;
}

.gallery-carousel-next path {
  fill: var(--foundation-white-white-50);
  transition: 400ms;
}

.gallery-carousel-next:hover path {
  fill: var(--primary-green-500);
  transition: 400ms;
}

.gallery-carousel-prev:hover path {
  fill: var(--primary-green-500);
  transition: 400ms;
}

.gallery-carousel-prev path {
  fill: var(--foundation-white-white-50);
  transition: 400ms;
}

.gallery-carousel-prev {
  position: absolute;
  left: 1rem;
  top: 50%;
  transition: 400ms;
}

.close-carousel {
  position: absolute;
  top: 20px;
  right: 20px;
  transition: 400ms;
}

.close-carousel:hover {
  cursor: pointer;
  scale: 1.2;
  transition: 400ms;
}

.close-carousel path {
  fill: var(--foundation-white-white-50);
  transition: 400ms;
}

.close-carousel:hover path {
  fill: var(--temperature-progress-bar);
  transition: 400ms;
}
