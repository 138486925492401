.help-screen-container {
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.date-info {
  color: var(--Foundation-White-white-900, #676767);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  background-color: var(--Foundation-White-white-600, #dfdfdf);
  width: fit-content;
  display: flex;
  padding: 0px 4px;
  align-items: flex-start;
  gap: 2px;
}

.form-container {
  display: flex;
  padding: 16px 0px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.title {
  display: flex;
  padding: 8px 0px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  color: var(--Foundation-Black-black-500, #131010);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.input-title {
  border: 1px solid var(--Foundation-Black-black-50, #e7e7e7);
  background: var(--Foundation-White-white-50, #fefefe);
  display: flex;
  padding: 16px;
  font-size: 16px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  margin-bottom: 8px;
}

.input-title:focus {
  outline: 2px solid var(--primary-green-600);
}

.input-log {
  display: flex;
  height: 248px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border: 2px solid var(--primary-green-600);
  padding: 0.75rem;
  font-family: Inter;
}

.input-log:focus {
  outline: none;
}

.helpScreen-deviceSelector-wrapper {
  width: 12.75rem;
  padding: 0.5rem 0rem;
}

.help-screen-success-message,
.help-screen-fail-message {
  color: var(--primary-green-700);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.help-screen-success-message {
  color: var(--Primary-primary-700, #1a9266);
}

.help-screen-fail-message {
  color: var(--foundation-red-red-600);
}
