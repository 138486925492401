.default-button {
  display: flex;
  min-width: fit-content;
  height: 48px;
  padding: 0.75rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
}

.default-button.default {
  background: var(--foundation-black-black-900);
  color: var(--foundation-white-white-50);
  outline: none;
  border: none;
  transition: 400ms;
}

.default-button.default:hover {
  transition: 400ms;
  background: var(--primary-green-500);
}

.default-button.white {
  border: 1px solid var(--Foundation-Black-black-500, #131010);
  background: var(--Foundation-White-white-50, #fefefe);
  color: var(--Foundation-Black-black-500, #131010);
}

.default-button.white:hover {
  background: var(--foundation-red-red-400);
  color: var(--foundation-white-white-50);
  outline: none;
  border: none;
}

.default-button.red {
  border: 1px solid var(--Foundation-Red-red-500, #f10400);
  background-color: var(--Foundation-Red-red-500, #f10400);
  color: var(--foundation-white-white-50);
}

.default-button.red:hover {
  transition: 400ms;
  background-color: var(--Foundation-Red-red-700, #ab0300);
}

.default-button.green {
  border: 1px solid var(--Primary-primary-500, #24cd90);
  background-color: var(--Primary-primary-500, #24cd90);
  color: var(--foundation-white-white-50);
}

.default-button.green:hover {
  transition: 400ms;
  background-color: var(--Primary-primary-700, #199266);
}

.default-button.dynamic {
  background: var(--primary-green-500);
  border: 1px solid var(--primary-green-500);
  color: var(--foundation-white-white-50);
}

.default-button.default.disabled {
  background: var(--foundation-white-white-600);
  cursor: not-allowed;
}

.close-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  padding: 4px;
}

.close-button.default {
  background: var(--Foundation-Red-red-600, #db0400);
}

.sidebar-button {
  display: flex;
  width: 10.375rem;
  padding: 0.5rem 0rem 0.5rem 0.75rem;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  cursor: pointer;
}

.sidebar-button.default {
  border: none;
  background: transparent;
  color: var(--Foundation-White-white-50, #fefefe);
}

.button-loader-wrapper {
  display: flex;
  column-gap: 0.75rem;
  align-items: center;
}

.button-loader-animation {
  width: 13px;
  height: 13px;
  border: 3px solid var(--Foundation-White-white-50, #fefefe);
  border-left-color: transparent;
  border-top-color: transparent;
  border-radius: 50%;
  -webkit-animation: spin 500ms cubic-bezier(0, 0, 0, 1.07) infinite;
  animation: spin 500ms cubic-bezier(0.155, 0.05, 0.255, 0.06) infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
