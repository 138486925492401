.element-loader {
  position: relative;
}

.element-loader.loading::after {
  content: 'Loading';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #d7d7d7aa;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
