.create-automation-modal-header {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--Foundation-Black-black-500, #131010);
  margin-right: 1rem;
}

.create-automation-modal-header svg {
  margin-right: 0.4rem;
}

.create-automation-modal-header svg path {
  fill: var(--Foundation-Black-black-500, #131010);
}
