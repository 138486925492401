.warning-modal-container {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background-color: rgba(0, 0, 0, 0.2);
}

.warning-modal-content {
    display: flex;
    flex-direction: column;
    background-color: var(--foundation-white-white-50);
    padding: 4rem;
    gap: 2rem;
    margin: 1rem;
    max-width: 30rem;
    box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.2);
}

.warning-modal-content-title {
    color: var(--Foundation-Black-black-500);
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.25rem;
}

.warning-modal-content-text {
    text-align: left;
    color: var(--Foundation-Black-black-500);
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
    padding-bottom: 1rem;
}

.warning-modal-button-row {
    display: flex;
    gap: 2rem;
    flex: 1;
    justify-content: center;
    align-self: stretch;
}

.default-button.warning-button {
    width: 6rem;
}

@media only screen and (max-width: 468px) {
    .warning-modal-content {
        padding: 2rem;
    }
}