.default-input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.default-input-label {
  display: flex;
  padding: 0.5rem 0rem;
  align-items: flex-start;
  gap: 0.25rem;
  align-self: stretch;
  color: var(--foundation-black-black-500);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.default-input {
  color: var(--foundation-black-black-500);
  outline: none;
  font-size: 1rem;
  border: none;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  width: initial;
  margin: 0;
  padding: 0;
  width: 100%;
  background: transparent;
}

.default-input-wrapper {
  display: flex;
  height: 2.5rem;
  padding: 0rem 0.5625rem;
  align-items: flex-start;
  gap: 0.25rem;
  align-items: center;
  align-self: stretch;
  flex-direction: row;
  border-radius: 0.25rem;
  border: 1px solid var(--foundation-white-white-600);
}

.default-input-wrapper:focus-within {
  border: 1px solid var(--foundation-black-black-500);
}

.default-input-wrapper.error {
  border: 2px solid var(--foundation-red-red-400, #f43633);
}

.default-input::placeholder {
  color: var(--foundation-black-black-100);
  opacity: 1;
}

.default-input:focus::placeholder {
  color: transparent;
}

.default-input-message {
  color: var(--foundation-black-black-100);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  padding-top: 0.25rem;
  padding-left: 0.25rem;
  text-align: left;
  width: 100%;
}

.default-input-message.error {
  color: var(--foundation-red-red-400, #f43633);
}