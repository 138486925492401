.upgrade-icon path {
  fill: var(--foundation-black-black-900);
}

.plans {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: space-evenly;
}

.plan-switch {
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 1rem 0;
}

.plan-text-active {
  font-weight: 600;
}
.plan-toggle-switch:hover {
  cursor: pointer;
}
