.default-dialog-container {
  position: absolute;
  display: flex;
  padding: 1.5rem;
  align-items: flex-start;
  flex-direction: column;
  top: 20px;
  right: 20px;
  background: var(--foundation-cyan-cyan-200);
  width: 20rem;
  box-shadow: 0px 23px 30.1px -19px rgba(0, 0, 0, 0.19);
}

.default-dialog-container.open {
  animation-duration: 1s;
  animation-name: show;
  transition: all 1s;
}

.default-dialog-container.closed {
  animation-duration: 1s;
  animation-name: hide;
  transition: all 1s;
}

@keyframes show {
  from {
    opacity: 0;
  }

  to {
    opacity: 100%;
  }
}

@keyframes hide {
  from {
    opacity: 100%;
  }

  to {
    opacity: 0;
  }
}

.default-dialog-content {
  width: 100%;
}

.default-dialog-header-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.default-dialog-header {
  color: var(--foundation-white-white-50);
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
  text-align: left;
  flex: 1;
}

.default-dialog-close-icon {
  display: flex;
  margin-left: 0.75rem;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  background: var(--foundation-cyan-cyan-300);
  cursor: pointer;
}

.default-dialog-close-icon:hover {
  background: var(--foundation-cyan-cyan-500);
}

@media only screen and (max-width: 767px) {
  .default-dialog-container {
    width: 10rem;
  }
}
