.system-checks-container {
  display: flex;
  padding: 0rem 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  align-self: stretch;
}

.system-checks-title {
  display: flex;
  padding: 6rem 0rem 2rem 0rem;
  align-items: flex-start;
  align-self: stretch;
  color: var(--foundation-black-black-500);
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
}

.system-checks-device-type-container {
  display: flex;
  min-width: 8.625rem;
  max-width: 22.6875rem;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0.5rem;
  align-self: stretch;
  flex-wrap: wrap;
}

.system-checks-device-type {
  display: flex;
  padding: 0.25rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  color: var(--foundation-black-black-500);
  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
}

.system-checks-device-type-status {
  width: 1rem;
  height: 1rem;
  background: var(--foundation-black-black-100);
}

.system-checks-device-type-status.box {
  background: var(--primary-green-500);
}

.system-checks-device-type-status.system {
  background: var(--primary-orange-500);
}

.system-checks-device-type-status.control {
  background: var(--primary-blue-500);
}

.system-checks-device-type-text {
  color: var(--foundation-black-black-500);
  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
}

.system-checks-device-type-text.disabled {
  color: var(--foundation-black-black-100);
}

.system-checks-indicator-container {
  display: flex;
  align-items: center;
  align-content: center;
  gap: 1.6875rem;
  flex-wrap: wrap;
  padding: 3rem 0;
}

.system-checks-indicator {
  display: flex;
  min-width: 15rem;
  align-items: center;
  gap: 1rem;
  flex: 0;
  text-align: left;
}

.system-checks-indicator-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--foundation-black-black-50);
  min-width: 5rem;
  min-height: 5rem;
  transition: all 0.5s;
}

.system-checks-indicator-icon-spinner {
  animation: 2s rollout;
}

@keyframes rollout {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.system-checks-indicator-icon.box {
  background: var(--primary-green-500);
}

.system-checks-indicator-icon.box path {
  fill: var(--foundation-white-white-50);
}

.system-checks-indicator-icon.system {
  background: var(--primary-orange-500);
}

.system-checks-indicator-icon.system path {
  fill: var(--foundation-white-white-50);
}

.system-checks-indicator-icon.control {
  background: var(--primary-blue-500);
}

.system-checks-indicator-icon.control path {
  fill: var(--foundation-white-white-50);
}

.system-checks-indicator-icon.error {
  background: var(--foundation-red-red-400);
}

.system-checks-indicator-icon.error path {
  fill: var(--foundation-white-white-50);
}

.system-checks-indicator-text {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.0625rem;
  color: var(--foundation-black-black-50);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.system-checks-indicator-text.box {
  color: var(--foundation-black-black-500);
}

.system-checks-indicator-text.system {
  color: var(--foundation-black-black-500);
}

.system-checks-indicator-text.control {
  color: var(--foundation-black-black-500);
}

.system-checks-indicator-text.error {
  color: var(--foundation-red-red-300);
}

.system-checks-button-container {
  padding-bottom: 2.5rem;
  display: flex;
  justify-content: center;
  width: 100%;
  align-self: end;
}

.system-checks-button-container .default-button {
  width: 100%;
  max-width: 23.5rem;
}

.system-checks-dialog-title {
  color: var(--foundation-white-white-50);
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
  text-align: left;
}

.system-checks-dialog-subtitle {
  color: var(--foundation-white-white-50);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
  text-align: left;
}

.system-checks-dialog-error-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 1rem;
  gap: 1rem;
}

.system-checks-dialog-error {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: var(--foundation-red-red-600);
}

.system-checks-dialog-error-icon {
  height: 1rem;
  width: 1rem;
  padding: 0.5rem;
}

.system-checks-dialog-error-message {
  color: var(--foundation-white-white-50);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
}

.default-dialog-container.error-container {
  position: absolute;
  max-width: 22rem;
  background: var(--foundation-red-red-400);
}

.default-dialog-container.error-container .default-dialog-close-icon {
  background: var(--foundation-red-red-600);
}

.default-dialog-container.error-container .default-dialog-close-icon:hover {
  background: var(--foundation-red-red-800);
}

.default-dialog-container.error-container.open {
  animation-duration: 1s;
  animation-name: slidein;
  transition: all 1s;
}

.default-dialog-container.error-container.closed {
  animation-duration: 1s;
  animation-name: slideout;
  transition: all 1s;
}

@keyframes slidein {
  from {
    right: -100%;
  }

  to {
    right: 0;
  }
}

@keyframes slideout {
  from {
    right: 0;
  }

  to {
    right: -100%;
  }
}

.system-checks-info-icon-container {
  padding: 2rem;
}

.default-button.default.error-dialog-button {
  width: 100%;
}

.default-button.default.error-dialog-button:hover {
  color: var(--foundation-black-black-500);
  background: var(--foundation-white-white-50);
}

@media only screen and (min-width: 768px) {
  .system-checks-container {
    padding: 0rem 6rem;
  }

  .system-checks-button-container .default-button {
    width: initial;
  }

  .system-checks-button-container {
    padding-bottom: 4.5rem;
    justify-content: end;
  }

  .system-checks-indicator-container {
    padding: 6.69rem 0;
  }
}

@media only screen and (min-width: 768px) {
  .system-checks-container {
    padding: 0rem 6rem;
  }

  .system-checks-button-container .default-button {
    width: initial;
  }
}
