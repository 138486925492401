.edit-modal-container-settings {
  display: flex;
  flex-direction: column;
  background-color: var(--foundation-white-white-50);
  width: 100%;
  max-width: 642px;
  padding: 1rem;
}

.edit-modal-box-settings {
  max-height: 75dvh; /* Fallback for browsers that do not support custom props */
  max-height: calc(var(--vh, 1dvh) * 75);
  overflow-y: auto;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.btn {
  padding: 12px 16px;
  width: fit-content;
  background-color: black;
  color: white;
}

.save-btn-modal {
  display: flex;
  gap: 8px;
  background-color: var(--primary-green-500);
  transition: 400ms;
}

.save-btn-modal:hover {
  transition: 400ms;
  cursor: pointer;
  background-color: var(--primary-green-700);
}

.remove-btn-modal {
  display: flex;
  gap: 8px;
  transition: 400ms;
  background-color: var(--Foundation-Red-red-500, #f10400);
}

.remove-btn-modal:hover {
  transition: 400ms;
  cursor: pointer;
  background-color: var(--Foundation-Red-red-700, #ab0300);
}

.button-row {
  display: flex;
  gap: 32px;
}

.close-btn-modal {
  transition: 400ms;
}

.close-btn-modal:hover {
  transition: 400ms;
  cursor: pointer;
  background-color: var(--primary-green-500);
}

.edit-modal {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(189, 189, 189, 0.8);
}

.edit-modal-control {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: -1.5rem;
}

.edit-modal-close-icon {
  width: 1rem;
  height: 1rem;
  cursor: pointer;
}

.edit-modal-close-icon path {
  fill: var(--foundation-black-black-500);
}

.edit-modal-close-icon:hover path {
  fill: var(--primary-green-500);
}

.edit-modal-section {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.edit-modal-label {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
}

.device-textarea {
  width: 100%;
  resize: none;
  overflow-y: hidden;
  min-height: 5rem;
  border-radius: 4px;
  border: 1px solid var(--Foundation-Black-black-50, #e7e7e7);
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  box-sizing: border-box;
  padding: 10px;
}

.device-textarea:focus {
  outline: none;
}

::-webkit-resizer {
  display: none;
}

.edit-modal-section span {
  display: flex;
  padding: 8px 0px;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  color: var(--Foundation-Black-black-500, #131010);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.icon-modal {
  width: 18px;
  height: 18px;
}

.settings-error-message {
  color: var(--foundation-red-red-600);
}

.btns-inline {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  gap: 2rem;
}
.btn {
  width: 7rem;
}

.btn-confirm {
  background-color: var(--foundation-green-green-500) !important;
}
.btn-cancel {
  background-color: var(--foundation-red-red-600) !important;
}
.btn-confirm:hover {
  background-color: var(--foundation-green-green-700) !important;
}
.btn-cancel:hover {
  background-color: var(--foundation-red-red-800) !important;
}

@media only screen and (max-width: 767px) {
  .edit-modal-container-settings {
    display: flex;
    flex-direction: column;
    background-color: var(--foundation-white-white-50);
    width: 100%;
    max-width: 642px;
    margin: 0 1.5rem;
    padding: 0rem;
  }
}
