.edit-modal-container-diary {
  display: flex;
  flex-direction: column;
  background-color: var(--foundation-white-white-50);
  width: 100%;
  max-width: 642px;
  padding: 1rem;
}

.edit-modal-box-diary {
  max-height: 75dvh; /* Fallback for browsers that do not support custom props */
  max-height: calc(var(--vh, 1dvh) * 75);
  overflow-y: auto;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

@media only screen and (max-width: 767px) {
  .edit-modal-container-diary {
    display: flex;
    flex-direction: column;
    background-color: var(--foundation-white-white-50);
    width: 100%;
    max-width: 642px;
    margin: 0 1.5rem;
    padding: 0rem;
  }
}
