.dashboard-content {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}

.dashboard-info-section {
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
  gap: 1rem;
}

.dashboard-info-section-device-status {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.indicator-container-splitter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  align-self: stretch;
  padding: 1rem 0;
}

.indicator-container {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 1rem;
  align-self: stretch;
  flex-wrap: wrap;
  flex: 1;
}

.indicator-card-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 0.375rem;
}

.indicator-card-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.default-progress-indicator-container.indicator-soil-ph .default-progress-indicator-track {
  background: #d4d298;
}

.default-progress-indicator-container.indicator-temperature .default-progress-indicator-track {
  background: #f43633;
}

/* @media only screen and (max-width: 760px) {
  .dashboard-info-section {
    flex-direction: column;
  }
} */
