.calibration-modal {
  background-color: var(--foundation-white-white-50);
  padding: 4rem;
  width: 80%;
}

.calibration-btns {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem 2rem;
  padding-top: 1rem;
}

.calibration-step-title {
  text-align: left;
  color: var(--foundation-black-black-500);
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 1.5rem;
  width: calc(100% - 2rem);
}

.calibration-step-text {
  color: rgba(0, 0, 0, 0.8);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  text-align: left;
  padding-bottom: 1rem;
}

.calibration-step-cancel {
  background-color: var(--foundation-red-red-400) !important;
  padding: 0.75rem 3.62rem;
}

.calibration-step-cancel:hover {
  background-color: var(--foundation-red-red-600) !important;
}

.calibration-icon-color path {
  fill: var(--foundation-white-white-50);
}

.calibration-btn-disabled {
  pointer-events: none;
  background-color: var(--foundation-black-black-100) !important;
}

.edit-modal-control-calibration {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: -1.3rem;
}

@media only screen and (max-width: 900px) {
  .calibration-btns {
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .calibration-btn {
    width: 15rem;
  }
}

@media only screen and (max-width: 620px) {
  .calibration-btns {
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .calibration-modal {
    padding: 2.5rem;
    width: 70%;
  }

  .calibration-btn {
    width: 100%;
  }
}
