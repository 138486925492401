.your-diary-header {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  padding: 1rem 0;
}

.your-diary-header.pt0 {
  padding-top: 0;
}

.your-diary-icon-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  min-width: fit-content;
}

.your-diary-text {
  color: var(--Foundation-Black-black-500, #131010);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-left: 8px;
  padding-right: 24px;
}

.divider {
  display: flex;
  height: 1px;
  background-color: var(--Foundation-Black-black-50, #e7e7e7);
  width: 100%;
  margin-left: 24px;
}