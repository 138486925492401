.ssx-checkbox {
  position: relative;
  padding-left: 2rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0.2rem;
  min-height: 1rem;
}

.ssx-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.ssx-checkbox .checkmark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 1.2rem;
  width: 1.2rem;
  border: 1px solid var(--Foundation-White-white-600, #dfdfdf);
  border-radius: 0.2rem;
  margin-left: 2px;
  text-align: center;

  transition: all 0.1s ease-out;
}

.ssx-checkbox.radio .checkmark {
  border-radius: 50%;
}

.ssx-checkbox:hover input ~ .checkmark {
  border-width: 3px;
  margin: 0px;
  border-color: var(--primary-green-500);
  background-color: var(--primary-green-200);
}

.ssx-checkbox .checkmark:after {
  color: var(--primary-green-500, #24cd90);
  font-size: 1rem;
  content: '\2713';
  position: absolute;
  display: none;
}

.ssx-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.ssx-checkbox .checkmark:after {
  width: 100%;
  height: 100%;
}

.ssx-checkbox:hover input:checked ~ .checkmark {
  background-color: var(--primary-green-500);
  border-color: var(--primary-green-200);
}

.ssx-checkbox:hover input:checked ~ .checkmark:after {
  color: var(--foundation-white-white-50);
}

.ssx-checkbox input:focus ~ .checkmark {
  outline: 5px auto rgba(0, 150, 255, 1);
}
