.modal-body {
  background-color: var(--foundation-white-white-50);
  width: 80%;
  max-height: 70%;
  overflow-y: auto;
  padding: 5rem 4rem;
}

.add-plant-title {
  text-align: left;
  color: var(--foundation-black-black-500);
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 2rem;
}
.add-plant-text {
  text-align: left;
  color: var(--foundation-black-black-500);
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
}

.add-plant-btns {
  display: flex;
  justify-content: flex-end;
  gap: 2rem;
}
.finish-setup-btns {
  margin-top: 5rem;
  display: flex;
  justify-content: flex-end;
  gap: 2rem;
}

.add-plant-btn {
  padding: 0.75rem 3.125rem;
}

.finish-setup-btn {
  background-color: var(--primary-green-500) !important;
}

.finish-setup-btn:hover {
  background-color: var(--primary-green-700) !important;
}

.btn-proceed {
  padding: 0.75rem 1.5rem;
  background-color: transparent !important;
  color: var(--foundation-black-black-500) !important;
  border: 1px solid var(--foundation-black-black-500) !important;
  transition: 400ms;
}

.btn-proceed:hover {
  background-color: var(--foundation-red-red-400) !important;
  color: var(--foundation-white-white-50) !important;
  border-color: transparent !important;
  transition: 400ms;
}

.btn-proceed-icon path {
  stroke: var(--foundation-black-black-500) !important;
  transition: 400ms;
}
.btn-proceed:hover .btn-proceed-icon path {
  stroke: var(--foundation-white-white-50) !important;
  transition: 400ms;
}

.add-plant-input-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 1.5rem;
}

.add-plant-result {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: var(--foundation-white-white-500);
  width: 100%;
  padding: 0.5rem 0.75rem;
  margin-bottom: 1rem;
  transition: 400ms;
}

.add-plant-result:hover {
  cursor: pointer;
  background-color: var(--primary-green-500);
  transition: 400ms;
}

.add-plant-result-checkmark {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1rem !important;
  height: 1rem !important;
  appearance: none;
  border-radius: 50%;
  border: 1px solid var(--foundation-black-black-100);
  transition: 400ms;
}

.add-plant-result:hover .add-plant-result-checkmark:after {
  content: '\2713';
  color: var(--foundation-black-black-500);
  transition: 400ms;
}

.add-plant-result-checkmark:checked:after {
  content: '\2713';
  color: var(--primary-green-500);
  transition: 400ms;
}
.add-plant-result-checkmark:checked {
  background-color: var(--foundation-white-white-50);
  transition: 400ms;
}

.add-plant-input {
  display: flex;
  height: 2.5rem;
  padding: 0rem 0.5625rem;
  align-items: flex-start;
  gap: 0.25rem;
  align-self: stretch;
  border-radius: 0.25rem;
  border: 1px solid var(--foundation-white-white-600);
  background: var(--foundation-white-white-50);
  width: 46%;
}

.add-plant-result-text {
  color: var(--foundation-black-black-500, #131010);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
}

.add-plant-normal-text {
  text-align: left;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.text-margin-bottom {
  margin: 2rem 0;
}

.checkbox-option {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
}

.automation-btns {
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.form-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
}

.icon-color path {
  fill: var(--foundation-black-black-500) !important;
}

.stages-form {
  height: 80%;
  overflow: scroll;
}

@media only screen and (max-width: 767px) {
  .add-plant-input {
    width: 100%;
  }
}
