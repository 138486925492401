.settingsScreen-mainContainer {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}

.settingsScreen-notificationArea {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.settingsScreen-notificationArea-title,
.settingsScreen-editProfileArea-title,
.settingsScreen-deviceSettingsArea-title {
  color: var(--Foundation-Black-black-500, #131010);
  text-align: left;
  font-family: Inter;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0.5rem 0rem;
}

.settingsScreen-notificationArea-item {
  width: 100%;
  display: flex;
  background: var(--Foundation-White-white-500, #f5f5f5);
  padding: 0.5rem 0.75rem;
  align-items: flex-start;
  box-sizing: border-box;
  gap: 1rem;
  align-items: center;
}

.settingsScreen-notificationArea-item:hover {
  background: var(--primary-green-500);
  color: var(--Foundation-White-white-500, #dfdfdf);
  transition: 400ms;
}

.settingsScreen-notificationArea-item:hover .custom-checkbox input:checked + span:before {
  color: var(--Foundation-White-white-600, #dfdfdf);
  transition: 400ms;
}

.settingsScreen-notificationArea-customRow {
  display: flex;
  column-gap: 1rem;
  row-gap: 1rem;
  padding: 1.5rem 0;
  flex-wrap: wrap;
}

.settingsScreen-notificationArea-customRow-item {
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  gap: 0.5rem;
  margin-left: 0.8rem;
  align-items: center;
}

.settingsScreen-editProfileArea {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.settingsScreen-editProfileArea-imageRow {
  display: flex;
  column-gap: 1.5rem;
}

.settingsScreen-editProfileArea-imageRow-imageBox {
  display: flex;
  width: 6.6875rem;
  height: 6.75rem;
}

.settingsScreen-editProfileArea-imageRow-imageBox img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.settingsScreen-editProfileArea-imageRow-buttonsColumn {
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
}

.settingsScreen-editProfileArea-nicknameRow {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  max-width: 25.75rem;
}

.settingsScreen-editProfileArea-nicknameRow-title {
  color: var(--Foundation-Black-black-500, #131010);
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  text-align: left;
}

.settingsScreen-editProfileArea-nicknameRow-input {
  display: flex;
  height: 2.5rem;
  padding: 0rem 0.5625rem;
  align-items: flex-start;
  gap: 0.25rem;
  align-self: stretch;
  border: 1px solid var(--Foundation-White-white-600, #dfdfdf);
  background: var(--Foundation-White-white-50, #fefefe);
}

.error-dialog{
  background-color: var(--foundation-red-red-800);
  gap: 1rem;
  color: var(--foundation-white-white-100);
}

.default-dialog-close-icon{
  background-color: #c69999;
  transition: 400ms;
}

.default-dialog-close-icon:hover{
  background-color: #a07575;
  transition: 400ms;
}