.indicator-header {
  display: flex;
  column-gap: 1.5rem;
  align-items: flex-start;
}

.indicator-title {
  color: var(--foundation-black-black-500);
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.5rem;
  height: fit-content;
  min-width: fit-content;
}

.indicator-divider-line {
  border: none;
  color: var(--foundation-black-black-50);
  background-color: var(--foundation-black-black-50);
  height: 1px;
  width: 100%;
  margin-top: 0.8rem;
}

.indicator-container-splitter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  align-self: stretch;
  padding: 1rem 0;
}

.conditions-overview-container {
  display: grid;
  column-gap: 1rem;
  row-gap: 1rem;
  grid-template-columns: auto;
}

.indicator-container {
  position: relative;
  display: grid;
  column-gap: 1rem;
  row-gap: 1rem;
  grid-template-columns: auto;
}

.device-offline::before {
  content: 'Device offline';
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
  font-style: normal;
  font-weight: 600;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: gray;
  z-index: 2;
  opacity: 0.2;
}

.no-connection::before {
  content: 'No internet connection';
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
  font-style: normal;
  font-weight: 600;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: gray;
  z-index: 2;
  opacity: 0.2;
}

.indicator-card-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 0.375rem;
}

.indicator-card-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.indicator-energy-icon {
  display: flex;
}

.indicator-energy-icon polygon {
  fill: var(--energy-usage-icon-color);
}

.indicator-power-icon {
  display: flex;
}

.indicator-power-icon.on path {
  fill: var(--foundation-green-green-400);
}

.indicator-power-icon.off path {
  fill: var(--foundation-red-red-400);
}

.indicator-card-intake-fan-position {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  padding-right: 0.25rem;
}

.indicator-card-exhaust-fan-position {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  padding-left: 0.25rem;
}

.indicator-card-fan-intake-icon {
  position: absolute;
  animation-name: spin-left;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.indicator-card-fan-intake-icon.speed-25 {
  animation-duration: 5000ms;
}

.indicator-card-fan-intake-icon.speed-50 {
  animation-duration: 2500ms;
}

.indicator-card-fan-intake-icon.speed-75 {
  animation-duration: 1000ms;
}

.indicator-card-fan-intake-icon.speed-100 {
  animation-duration: 500ms;
}

.indicator-card-fan-exhaust-icon {
  position: absolute;
  animation-name: spin-right;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.indicator-card-fan-exhaust-icon.speed-25 {
  animation-duration: 5000ms;
}

.indicator-card-fan-exhaust-icon.speed-50 {
  animation-duration: 2500ms;
}

.indicator-card-fan-exhaust-icon.speed-75 {
  animation-duration: 1000ms;
}

.indicator-card-fan-exhaust-icon.speed-100 {
  animation-duration: 500ms;
}

.indicator-card-icon-overlay {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.default-progress-indicator-container.soil-acidity-sensor .default-progress-indicator-track {
  background: var(--ph-progress-bar);
}

.default-progress-indicator-container.air-temperature-sensor .default-progress-indicator-track {
  background: var(--temperature-progress-bar);
}

.dashboard-sensor-calibration {
  width: fit-content;
  background-color: var(--primary-green-500) !important;
}

.dashboard-sensor-calibration:hover {
  cursor: pointer;
  background-color: var(--primary-green-700) !important;
}

@media only screen and (min-width: 800px) {
  .indicator-container {
    grid-template-columns: calc(50% - 0.5rem) calc(50% - 0.5rem);
  }
}

@media only screen and (min-width: 1500px) {
  .indicator-container {
    grid-template-columns: calc(33.33% - 0.66rem) calc(33.33% - 0.66rem) calc(33.33% - 0.66rem);
  }
}

@media only screen and (min-width: 2200px) {
  .indicator-container {
    grid-template-columns: calc(25% - 0.75rem) calc(25% - 0.75rem) calc(25% - 0.75rem) calc(
        25% - 0.75rem
      );
  }
}

@media only screen and (min-width: 2900px) {
  .indicator-container {
    grid-template-columns:
      calc(20% - 0.8rem) calc(20% - 0.8rem) calc(20% - 0.8rem) calc(20% - 0.8rem)
      calc(20% - 0.8rem);
  }
}

@keyframes spin-right {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes spin-left {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
}
