.layout-wrapper {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
}

.layout-wrapper.system {
  --primary-green-200: #ffb68a;
  --primary-green-400: #ff8033;
  --primary-green-500: #ff6000;
  --primary-green-600: #e85700;
  --primary-green-700: #b54400;
}

.layout-wrapper.control {
  --primary-green-200: #bcc7ff;
  --primary-green-400: #8a9dff;
  --primary-green-500: #6d85ff;
  --primary-green-600: #6379e8;
  --primary-green-700: #4d5eb5;
}

.main-content-area {
  display: flex;
  width: 100%;
  height: auto;
  overflow-y: auto;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  flex-grow: 1;
  background: var(--dashboard-content-background);
  width: 100%;
  padding: 1rem;
  padding-bottom: 3rem;
  overflow-y: scroll;
  height: 100%;
}

.content::-webkit-scrollbar {
  width: 7px;
}

/* Handle */
.content::-webkit-scrollbar-thumb {
  background: var(--primary-green-500);
  border-radius: 10px;
}

/* Handle on hover */
.content::-webkit-scrollbar-thumb:hover {
  background: var(--primary-green-400);
}

@media only screen and (min-width: 768px) {
  .content {
    padding: 1.5rem;
    padding-bottom: 3rem;
  }
}

@media only screen and (min-width: 1440px) {
  .content {
    padding: 4rem;
    height: calc(100vh - 6rem);
  }
}
