.login-container {
  display: flex;
  padding: 0rem 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  width: 100%;
  max-height: calc(var(--vh, 1dvh) * 90);
  overflow: scroll;
}

.login-title {
  display: flex;
  padding: 2rem 0rem 0rem;
  align-items: flex-start;
  align-self: stretch;
  color: var(--foundation-black-black-500);
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.login-content-splitter {
  display: flex;
  flex-direction: column;
  padding: 2rem 0rem;
  align-items: flex-start;
  align-content: flex-start;
  gap: 1rem;
  align-self: stretch;
  flex-wrap: wrap;
  flex: 1 0;
}

.login-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.login-alternative-login {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  color: var(--foundation-black-black-500);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.login-alternative-container {
  display: flex;
  padding: 1rem 0rem;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
}

.login-alternative {
  display: flex;
  padding: 0.75rem;
  width: 3rem;
  height: 3rem;
  justify-content: center;
  align-items: flex-start;
  gap: 0.25rem;
  border-radius: 0.25rem;
  cursor: pointer;
  border: 1px solid var(--foundation-white-white-600);
}

.login-alternative-icon {
  align-self: center;
}

.show-password-icon {
  cursor: pointer;
}

.show-password-icon path {
  fill: var(--foundation-black-black-50);
}

.login-create-account {
  display: flex;
  flex-wrap: wrap;
  color: var(--foundation-black-black-500);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.login-link {
  color: var(--foundation-black-black-500);
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  cursor: pointer;
}

.login-button-container {
  padding-bottom: 2rem;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-self: end;
}

.login-button-container .default-button {
  width: 100%;
}

.login-label-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.login-email-icon {
  display: flex;
  padding: 0.25rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
}

.login-key-icon {
  display: flex;
  padding: 0.25rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
}

@media only screen and (min-width: 768px) {
  .login-container {
    padding: 0rem 6rem;
    max-height: unset;
    overflow: hidden;
  }

  .login-title {
    padding: 6rem 0 1rem 0;
  }

  .login-content-splitter {
    flex-direction: row;
  }

  .login-button-container .default-button {
    width: initial;
  }
}

@media only screen and (min-width: 1024px) {
  .login-content-splitter {
    flex-direction: row;
  }

  .login-form-container {
    flex: 1 0;
  }

  .login-alternative-login {
    flex: 1 0;
  }
}
