.multiple-dropdown-selector-container {
  position: relative;
  flex: 1;
}

.multiple-dropdown-selector-wrapper {
  display: flex;
  height: 1.5rem;
  align-items: center;
  gap: 0.75rem;
  align-self: stretch;
  padding: 0.5rem;
  border: 1px solid var(--foundation-black-black-50);
  cursor: pointer;
}

.multiple-dropdown-selector-icon-container {
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--primary-green-500);
}

.multiple-dropdown-selector-text {
  color: var(--foundation-black-black-500);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.multiple-dropdown-selector-wrapper:hover {
  background: var(--primary-green-500);
}

.multiple-dropdown-selector-wrapper:hover .multiple-dropdown-selector-text {
  color: var(--foundation-white-white-50);
}

.multiple-dropdown-selector-wrapper:hover .multiple-dropdown-selector-icon-container {
  background: var(--primary-green-600);
}

.multiple-dropdown-selector-icon-container.active {
  transform: rotate(180deg);
}

.multiple-dropdown-selector-item-container {
  display: none;
  position: absolute;
  width: 250px;
  box-sizing: border-box;
  max-height: 0;
  width: 100%;
  background: var(--foundation-white-white-50);
  border: 1px solid var(--foundation-black-black-50);
  transition:
    max-height ease 0.75s,
    border ease 0.75s,
    background-color ease 0.75s;
  overflow: scroll;

  gap: 0.4rem;
}

.multiple-dropdown-selector-item {
  padding: 0.5rem;
  align-self: stretch;
  text-align: left;
  color: var(--foundation-black-black-500);
  text-overflow: ellipsis;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.multiple-dropdown-selector-item:hover {
  background: var(--primary-green-600);
  color: var(--foundation-white-white-50);
  cursor: pointer;
}

.multiple-dropdown-selector-item.selected:hover {
  background: var(--primary-green-600);
  color: var(--foundation-white-white-50);
  cursor: pointer;
}

.multiple-dropdown-selector-item.selected {
  background: var(--foundation-black-black-50);
  cursor: pointer;
}

.multiple-dropdown-selector-item-container.active {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 20rem;
  padding: 0.5rem 0;
  z-index: 10;
}
