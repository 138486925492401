.modal-container {
  position: absolute;
  width: 880px;
  min-height: 386px;
  display: flex;
  row-gap: 32px;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 0 0 50vmax rgba(0, 0, 0, 0.5);
  z-index: 100;
  border-top: 4px solid var(--foundation-red-red-600);
  background-color: var(--foundation-white-white-50);
  box-sizing: border-box;
  padding: 60px;
  box-shadow: rgba(0, 0, 0, 0.03);
}

.title-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.title-row-text {
  display: flex;
  color: var(--foundation-black-black-500);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.input-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-height: 128px;
  row-gap: 16px;
}

.input-row-text {
  color: var(--Foundation-Black-black-500, #131010);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.input-row-textbox {
  width: 100%;
  resize: none;
  overflow-y: hidden;
  min-height: 64px;
  border-radius: 4px;
  border: 1px solid var(--Foundation-Black-black-50, #e7e7e7);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  box-sizing: border-box;
  padding: 10px;
}

.input-row-textbox:focus {
  outline: none;
}

::-webkit-resizer {
  display: none;
}

.steps-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-height: 128px;
  color: var(--Foundation-Black-black-500, #131010);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.steps-row.troubleshoot {
  row-gap: 16px;
}

.steps-row.success {
  row-gap: 0px;
}

.button-row {
  display: flex;
  width: 100%;
  column-gap: 24px;
}

.modal-helper-body {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--hamburger-background);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .modal-container {
    width: 568px;
    min-height: 368px;
    padding: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .modal-container {
    width: 328px;
    min-height: 368px;
    padding: 60px 32px;
  }

  .button-row {
    flex-direction: column;
    width: 100%;
    row-gap: 24px;
  }

  .steps-row {
    text-align: left;
  }

  .version-modal-content {
    width: 60%;
    padding: 3rem;
  }
}