.ssx-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100;
  background-color: var(--hamburger-background);
}

.ssx-modal-overlay.center {
  justify-content: center;
}

.ssx-modal-overlay .ssx-modal-container {
  box-sizing: border-box;
  max-width: 768px;
  padding: 2rem;
  background-color: var(--foundation-white-white-50);
  overflow-y: auto;
  margin: 0.6rem;
  max-height: calc(100% - 1.2rem);
}

.ssx-modal-overlay .ssx-modal-header {
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.ssx-modal-overlay .ssx-modal-header-close-icon {
  cursor: pointer;
  margin-left: 0.6rem;
}

.ssx-modal-overlay .ssx-modal-header-close-icon path {
  fill: var(--foundation-black-black-500);
}

.ssx-modal-overlay .ssx-modal-header-close-icon:hover path {
  fill: var(--foundation-red-red-600);
}

.title-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.title-row-text {
  display: flex;
  color: var(--foundation-black-black-500);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.input-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-height: 128px;
  row-gap: 16px;
}

.input-row-text {
  color: var(--Foundation-Black-black-500, #131010);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.input-row-textbox {
  width: 100%;
  resize: none;
  overflow-y: hidden;
  min-height: 64px;
  border-radius: 4px;
  border: 1px solid var(--Foundation-Black-black-50, #e7e7e7);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  box-sizing: border-box;
  padding: 10px;
}

.input-row-textbox:focus {
  outline: none;
}

::-webkit-resizer {
  display: none;
}

.steps-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-height: 128px;
  color: var(--Foundation-Black-black-500, #131010);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.steps-row.troubleshoot {
  row-gap: 16px;
}

.steps-row.success {
  row-gap: 0px;
}

.button-row {
  display: flex;
  width: 100%;
  column-gap: 24px;
}

.modal-helper-body {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--hamburger-background);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .modal-container {
    width: 568px;
    min-height: 368px;
    padding: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .modal-container {
    width: 328px;
    min-height: 368px;
    padding: 60px 32px;
  }

  .button-row {
    flex-direction: column;
    width: 100%;
    row-gap: 24px;
  }

  .steps-row {
    text-align: left;
  }

  .version-modal-content {
    width: 60%;
    padding: 3rem;
  }

  .ssx-modal-overlay .ssx-modal-container {
    padding: 1rem;
  }
}
