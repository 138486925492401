.default-link {
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  color: var(--foundation-black-black-100);
  cursor: pointer;
}

.default-link:visited {
  color: var(--foundation-red-red-800);
}

.default-link:hover {
  color: var(--foundation-red-red-600);
}
