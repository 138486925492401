.entry {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 0.75rem;
  transition: 400ms;
  background: var(--Foundation-White-white-500, #f5f5f5);
}

.entry.selected {
  cursor: pointer;
  background: var(--primary-green-500) !important;
}

.entry.selected .entry-text {
  color: var(--foundation-white-white-50, #fefefe);
}

.entry.selected .entry-text-wide {
  color: var(--foundation-white-white-50, #fefefe);
}

.entry.selected .entry-date {
  color: var(--foundation-black-black-50, #e7e7e7);
  transition: 400ms;
}

.entry:hover {
  cursor: pointer;
  background: var(--primary-green-500) !important;
  transition: 400ms;
}

.entry-text {
  color: var(--Foundation-Black-black-500, #131010);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  transition: 400ms;
}

.entry-stage-wrapper {
  display: flex;
  column-gap: 1.5rem;
  flex-wrap: wrap;
}

.entry:hover .entry-text {
  transition: 400ms;
  color: var(--foundation-white-white-50, #fefefe);
}

.entry:hover .entry-text-wide {
  transition: 400ms;
  color: var(--foundation-white-white-50, #fefefe);
}

.entry:hover div {
  color: var(--Foundation-White-white-50, #fefefe);
}

.entry-date {
  color: var(--foundation-black-black-200, #929191);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  min-width: fit-content;
  transition: 400ms;
}

.entry:hover .entry-date {
  color: var(--foundation-black-black-50, #e7e7e7);
  transition: 400ms;
}
.entry:hover .entry-notes {
  color: var(--foundation-black-black-50, #e7e7e7);
  transition: 400ms;
}

.entry-device {
  color: var(--foundation-black-black-200, #929191);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  flex: 1;
  align-self: stretch;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
}

.entry-text-wide {
  color: var(--Foundation-Black-black-500, #131010);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  width: 100%;
}

.entry-icon {
  margin-left: auto;
  display: flex;
}

.entry:hover .entry-icon path {
  fill: var(--Foundation-White-white-50, #fefefe);
}

.entries-container {
  padding: 16px 0;
  display: flex;
  flex-direction: column;
}

.entries-container .entry {
  margin-bottom: 8px;
}

.entries-container div:nth-child(even) .entry {
  background: var(--Foundation-White-white-200, #fafafa);
}

.status {
  display: flex;
  justify-content: center;
  align-items: center;
}

.status-icon-inactive circle {
  fill: var(--Foundation-Black-black-50, #e7e7e7);
}

.entry-notes {
  font-family: Inter;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--foundation-black-black-100);
  transition: 400ms;
  white-space: nowrap;
}

@media only screen and (max-width: 767px) {
  .entry-text {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media only screen and (max-width: 600px) {
  .entry .entry-text {
    max-width: 120px;
    min-width: 120px;
  }

  .entry .entry-notes {
    white-space: wrap;
  }
}
