.notification-base-container {
  position: fixed;
  right: 1.2rem;
  top: 1.2rem;
  text-align: left;
  z-index: 600;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  --notification-progress-bar-height: 0.4rem;
}

.notification-base {
  color: var(--foundation-white-white-50);
  animation: slide-in 0.5s ease-in-out;
  position: relative;

  background-color: var(--primary-green-500);
  max-width: 22rem;
  box-shadow: 0px 23px 30.1px -19px rgba(0, 0, 0, 0.19);
  box-shadow: 0px 23px 30.1px -19px rgba(0, 0, 0, 0.19);
  overflow: hidden;
  margin-bottom: 1rem;
  display: inline-block;
}

.notification-base.error {
  background: var(--foundation-red-red-400);
}

.notification-base-content {
  display: inline-flex;
  padding: 1.5rem;
  padding-bottom: calc(1.5rem - var(--notification-progress-bar-height));
  align-items: flex-start;
  flex-direction: column;
}

.notification-base-header {
  font-style: normal;
  font-weight: 700;
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.notification-base-body {
  margin-top: 1rem;
}

.notification-base-out {
  animation: slide-out 0.5s ease-in-out;
  opacity: 0;
  transform: translateX(100%);
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-out {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

.notification-base .progress-bar {
  width: 100%;
  background: var(--foundation-green-green-700);
  height: var(--notification-progress-bar-height);
  transition-property: width;
}

.notification-base.error .progress-bar {
  background: var(--foundation-red-red-600);
}

.notification-base .progress-bar.paused {
  visibility: hidden;
  animation: none;
}

.notification-base-close-icon {
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--foundation-green-green-600);
  cursor: pointer;
}

.notification-base-close-icon:hover {
  background: var(--foundation-green-green-700);
}

.notification-base.error .notification-base-close-icon:hover {
  background: var(--foundation-red-red-800);
}

.notification-base-header-text {
  flex: 1;
  margin-right: 0.6rem;
}

.notification-base .progress-bar {
  animation: notification-timer-animation 5s linear;
  transform: translateX(100%);
}

@keyframes notification-timer-animation {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

.save-btn-modal {
  display: flex;
  gap: 8px;
  background-color: var(--primary-green-500);
  transition: 400ms;
}

.save-btn-modal:hover {
  transition: 400ms;
  cursor: pointer;
  background-color: var(--primary-green-700);
}
