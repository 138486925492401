.table_row_style:hover {
  cursor: pointer;
  background-color: var(--foundation-white-white-500);
}

.table_row_style {
  padding: 0.5rem;
}

.admin-dashboard h1 {
  margin-bottom: 1rem;
}

.table-automation-status {
  margin-right: 0.5rem;
}
