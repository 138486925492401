.ssx-table-row {
  display: flex;
  background: var(--foundation-white-white-500);
  padding: 0.6rem;
  margin: 0.6rem 0;
  align-items: center;

  transition:
    color 0.1s,
    background-color 0.1s;
}

.ssx-table-row.odd {
  background: var(--foundation-white-white-300);
}

.ssx-table-row.hovered:hover {
  cursor: pointer;
  background-color: var(--primary-green-400);
}

.ssx-table-row > *:not(:last-child) {
  margin-right: 0.6rem;
}
