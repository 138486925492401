:root {
  --foundation-black-black-25: #e7e7e7;
  --foundation-black-black-50: #e7e7e7;
  --foundation-black-black-100: #b6b5b5;
  --foundation-black-black-200: #929191;
  --foundation-black-black-400: #424040;
  --foundation-black-black-500: #131010;
  --foundation-black-black-600: #110f0f;
  --foundation-black-black-900: #080707;
  --foundation-white-white-50: #fefefe;
  --foundation-white-white-100: #fcfcfc;
  --foundation-white-white-500: #f5f5f5;
  --foundation-white-white-600: #dfdfdf;
  --foundation-red-red-200: #f98c8a;
  --foundation-red-red-300: #f65754;
  --foundation-red-red-400: #f43633;
  --foundation-red-red-600: #db0400;
  --foundation-red-red-800: #850200;
  --foundation-cyan-cyan-200: #27bcd0;
  --foundation-cyan-cyan-300: #4acadc;
  --foundation-cyan-cyan-500: #1395a6;
  --foundation-green-green-400: #8cd38d;
  --foundation-green-green-500: #24cd90;
  --foundation-green-green-700: #199266;
  --primary-green-200: #9ae8cc;
  --primary-green-400: #50d7a6;
  --primary-green-500: #24cd90;
  --primary-green-600: #21bb83;
  --primary-green-700: #199266;
  --primary-orange-500: #ff6000;
  --primary-blue-500: #6d85ff;
  --Blue-Progress-Bar: #508cff;
  --orange-switch-button: #fe9800;
  --primary-orange-200: #ffb68a;
  --primary-orange-400: #ff8033;
  --primary-orange-500: #ff6000;
  --primary-orange-600: #e85700;
  --primary-orange-700: #b54400;
  --primary-blue-200: #bcc7ff;
  --primary-blue-400: #8a9dff;
  --primary-blue-500: #6d85ff;
  --primary-blue-600: #6379e8;
  --primary-blue-700: #4d5eb5;
  --dashboard-content-background: #fcfcfc;
  --ph-progress-bar: #d4d298;
  --temperature-progress-bar: #f43633;
  --energy-usage-icon-color: #f5ee13;
  --hamburger-background: rgba(182, 181, 181, 0.7);
  --gallery-background: rgba(0, 0, 0, 0.9);
}
