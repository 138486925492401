.custom-checkbox input {
  display: none;
}

.custom-checkbox.default span,
.custom-checkbox.round span {
  border: 1px solid var(--Foundation-White-white-600, #dfdfdf);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-checkbox.default span {
  width: 1.1rem;
  height: 1.1rem;
  border-radius: 0.2rem;
}

.custom-checkbox.round span {
  width: 1rem;
  height: 1rem;
  border-radius: 2.4375rem;
}

.custom-checkbox input:checked + span:before {
  color: var(--primary-green-500, #24cd90);
  content: '\2713';
  display: flex;
}
