.indicator-card {
  display: flex;
  min-width: 14.5rem;
  height: 7.5rem;
  padding: 1.5rem 1.5625rem 0rem 1.5625rem;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid var(--foundation-white-white-600);
  background: var(--foundation-white-white-50);
}

.indicator-card-wrapper {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
}

.indicator-card-icon {
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  height: 4rem;
  width: 4rem;
  border: 1px solid var(--foundation-white-white-600);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.375rem;
}

.indicator-card-header-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
}

.air-temperature-controller-subtitle .indicator-card-header-subtitle {
  font-size: 1.1rem;
}

.indicator-card-header-title {
  color: var(--Foundation-Black-black-500);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.indicator-card-header-subtitle {
  color: var(--Foundation-Black-black-500);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.25rem;
}

.indicator-card-header-subtitle.module_status {
  font-size: 1rem;
  text-align: left;
  line-height: 1.25;
}

.indicator-card-progress-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.indicator-card-controller-wrapper {
  display: flex;
  padding: 1rem 0rem 1.25rem 0rem;
  align-items: flex-end;
  gap: 0.5rem;
  flex: 1 0 0;
  align-self: stretch;
}

.indicator-card-controller-button {
  border: none;
  cursor: pointer;
  display: flex;
  width: 6.1875rem;
  height: 1.25rem;
  padding: 0rem 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.125rem;
  background: var(--foundation-black-black-400);
  color: var(--foundation-white-white-50);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1rem;
}

.indicator-card-fan-controller-button {
  border: none;
  cursor: pointer;
  display: flex;
  width: 6.1875rem;
  height: 1.25rem;
  padding: 0rem 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.125rem;
  background: var(--foundation-black-black-400);
  color: var(--foundation-white-white-50);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1rem;
}

.indicator-card-fan-controller-button:hover {
  background: var(--primary-green-400);
}

.indicator-card-fan-controller-button.disabled {
  background: var(--foundation-black-black-400);
  cursor: not-allowed;
}

.indicator-card-pause-icon {
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--foundation-white-white-600);
}

.indicator-card-pause-icon:hover {
  background: var(--foundation-red-red-400);
  cursor: pointer;
}

.indicator-card-pause-icon:hover path {
  stroke: var(--foundation-white-white-50);
}

.indicator-card-pause-icon path {
  stroke: var(--foundation-red-red-400);
}

.indicator-card-play-icon {
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--foundation-white-white-600);
}

.indicator-card-play-icon:hover {
  background: var(--foundation-green-green-400);
  cursor: pointer;
}

.indicator-card-play-icon:hover path {
  stroke: var(--foundation-white-white-50);
}

.indicator-card-play-icon path {
  stroke: var(--foundation-green-green-400);
}

.indicator-card-fan-speed {
  color: var(--foundation-black-black-500);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
}

.indicator-card-loader-wrapper {
  display: flex;
  column-gap: 0.75rem;
  align-items: center;
}

.indicator-card-temperature-controller-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.indicator-card-temperature-controller-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.indicator-card-temperature-controller-input {
  width: 4rem;
}

.indicator-card-loader-animation {
  width: 1.5rem;
  height: 1.5rem;
  border: 0.25rem solid var(--foundation-black-black-500);
  border-left-color: transparent;
  border-top-color: transparent;
  border-radius: 50%;
  -webkit-animation: spin 500ms cubic-bezier(0, 0, 0, 1.07) infinite;
  animation: spin 500ms cubic-bezier(0.155, 0.05, 0.255, 0.06) infinite;
}

.indicator-card-loader-animation.small {
  width: 0.75rem;
  height: 0.75rem;
  border: 0.125rem solid var(--foundation-black-black-500);
  border-left-color: transparent;
  border-top-color: transparent;
  border-radius: 50%;
  -webkit-animation: spin 500ms cubic-bezier(0, 0, 0, 1.07) infinite;
  animation: spin 500ms cubic-bezier(0.155, 0.05, 0.255, 0.06) infinite;
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.indicator-card-controller-button:hover {
  background: var(--foundation-black-black-600);
}

.indicator-card-controller-button.active {
  background: var(--primary-green-400);
}

.indicator-card-controller-button.active:hover {
  background: var(--primary-green-600);
}

.default-progress-indicator-container.card-indicator {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .air-temperature-controller {
    height: auto;
  }

  .indicator-card-fan-controller-button.temp {
    width: auto;
  }
}
