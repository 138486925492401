.modal-body {
  background-color: var(--foundation-white-white-50);
  width: 80%;
  max-height: 70%;
  overflow-y: auto;
  padding: 5rem 4rem;
  text-align: left;
}

.modal-title {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
}

.modal-content {
  text-align: left;
  margin-bottom: 2rem;
}

.modal-content-section {
  text-align: left;
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: nowrap;
  font-weight: 600;
  margin-bottom: 1rem;
}

.modal-content-section-divider {
  height: 1px;
  width: 100%;
  background-color: var(--foundation-black-black-100);
}

.section-content {
  padding-left: 2rem;
  margin-bottom: 1rem;
}

.no-automation-text {
  color: red;
  font-weight: 600;
}

.automation-status {
  font-size: 1rem !important;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.input-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  max-height: 1.5rem;
  cursor: pointer;
}

.save-icon-text {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: var(--primary-green-600);
  color: white;
  padding: 0.1rem 0.5rem;
  height: 1.5rem;
  font-size: 0.8rem;
  transition: 400ms all;
}

.save-icon-text:hover {
  background-color: var(--primary-green-700);
  transition: 400ms all;
}
