.version-modal-content {
  padding: 4rem;
  background-color: white;
  height: fit-content;
  max-height: 80%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 40.125rem;
  z-index: 9999;
  text-align: left;
}

.version-modal-button {
  margin-top: 1.5rem;
  padding: 0.75rem 2rem;
  width: fit-content;
}

.version-modal-title {
  color: var(--Foundation-Black-black-500, #131010);
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
  padding: 0.5rem 0rem;
}
.version-modal-text {
  color: var(--Foundation-Black-black-500, #131010);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  overflow-y: auto;
}

.version-modal-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--hamburger-background);
  z-index: 9999;
}
