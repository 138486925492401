.card-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
  gap: 0.5rem;
  width: 15rem;
  min-width: 15rem;
  flex: 1 0 0;
  max-width: 20rem;
}

.card-title {
  color: var(--foundation-black-black-500, #131010);
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.card-price {
  text-align: left;
  padding-bottom: 1.5rem;
  color: var(--primary-green-600);
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.card-price-m {
  text-align: left;
  margin-top: 0.5rem;
  min-height: 3rem;
  color: var(--primary-green-600);
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.icon-active path {
  fill: var(--primary-green-600);
}

.card-control-types {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.card-text-bold {
  text-align: left;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
}

.card-text-normal {
  text-align: left;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.card-disabled {
  text-align: left;
  color: var(--foundation-black-black-100);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.card-bold-disabled {
  text-align: left;
  color: var(--foundation-black-black-100);
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
}

.card-divider {
  height: 1px;
  background-color: var(--foundation-white-white-600);
  width: 100%;
  margin: 0.5rem 0;
}

.card-btn {
  margin-top: 0.5rem;
  width: 100%;
}
