.sidebar-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  min-width: 15rem;
  box-sizing: border-box;
  background: var(--primary-green-600, #21bb83);
}

.sidebar-body {
  display: flex;
  flex-direction: column;
  padding: 2rem 0rem;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  row-gap: 1rem;
}

.sidebar-body > a {
  width: 100%;
  display: flex;
  justify-content: center;
}

.sidebar-body > a:hover {
  background-color: var(--primary-green-500);
}

.sidebar-button.default path {
  fill: var(--primary-green-200);
}

@media only screen and (max-width: 1440px) {
  .sidebar-container {
    display: none;
  }
}
