.default-progress-indicator-container {
  min-width: 5rem;
  position: relative;
  height: 0.5rem;
  width: 100%;
  background: var(--foundation-white-white-600);
}

.default-progress-indicator-track {
  position: absolute;
  height: 100%;
  background: #508cff;
}
