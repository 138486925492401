.recaptcha-dialog-title {
  color: var(--foundation-black-black-500);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.recaptcha-dialog-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.25rem;
}

.recaptcha-dialog {
  margin: 0;
}

.recaptcha-dialog-title {
  width: 300px;
}

.default-button.recaptcha-confirm-button {
  width: 100%;
}

.default-button.recaptcha-cancel-button {
  background: var(--foundation-white-white-50);
  color: var(--foundation-black-black-500);
  border: 1px solid var(--foundation-black-black-500);
  width: 100%;
}

.default-button.recaptcha-cancel-button:hover {
  color: var(--foundation-white-white-50);
  background: var(--foundation-red-red-400);
  border: 1px solid var(--foundation-red-red-400);
}
