.connect-container {
  display: flex;
  padding: 0rem 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  align-self: stretch;
}

.connect-title {
  display: flex;
  padding: 6rem 0rem 1rem 0rem;
  align-items: flex-start;
  align-self: stretch;
  color: var(--foundation-black-black-500);
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.connect-text-container {
  display: flex;
  padding: 4rem 0rem;
  align-items: center;
  gap: 3.75rem;
  align-self: stretch;
  flex-wrap: wrap;
  color: var(--foundation-black-black-500);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.connect-text {
  color: var(--foundation-black-black-500);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.connect-mobile-content {
  display: flex;
  padding: 0.625rem 0rem;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  align-self: center;
}

.connect-scan-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
}

.connect-separator-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.75rem;
  width: 100%;
}

.connect-separator {
  background: var(--foundation-black-black-50);
  height: 1px;
  flex: 1 0;
}

.connect-separator-text {
  color: var(--foundation-black-black-500);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
}

.connect-scan-text {
  color: var(--foundation-black-black-500);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.connect-qr-reader {
  width: 17rem;
  height: 17rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid var(--foundation-black-black-50);
}

.connect-qr-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  flex: 1 0 0;
  border: 1px solid var(--foundation-black-black-50);
  padding: 2rem;
  align-self: stretch;
  flex: 1;
}

.connect-qr-code {
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(/src/assets/QR.png);
  background-color: var(--foundation-white-white-50);
  background-repeat: no-repeat;
  background-size: cover;
  width: 13rem;
  height: 13rem;
}

.connect-qr-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: var(--foundation-black-black-100);
  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
  background: url(/src/assets/QRText.png);
  border-radius: 15rem;
  padding: 1rem;
  height: 6rem;
  width: 6rem;
  background: var(--foundation-white-white-50);
  position: absolute;
}

.connect-button-container {
  padding-bottom: 2.5rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
}

.connect-button-container .default-button {
  width: 100%;
  max-width: 23.5rem;
}

.connect-dialog-header-container {
  display: flex;
  padding: 0.25rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  flex: 1 0 0;
  color: var(--foundation-white-white-50);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
}

.default-link.connect-dialog-link {
  color: var(--foundation-white-white-50);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
  text-decoration: underline;
  cursor: pointer;
}

.default-link.connect-dialog-link:visited {
  color: var(--foundation-cyan-cyan-300);
}

.default-link.connect-dialog-link:hover {
  color: var(--foundation-cyan-cyan-500);
}

.connect-info-container {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.connect-info-icon-container {
  padding: 2rem;
}

.connect-info-icon:hover {
  cursor: pointer;
}

.connect-info-icon:hover path {
  fill: var(--foundation-cyan-cyan-300);
}

@media only screen and (min-width: 768px) {
  .connect-container {
    padding: 0rem 6rem;
  }

  .connect-text-container {
    padding: 2.5rem 0;
    flex: 1;
  }

  .connect-step-container {
    padding: 5rem 0;
  }

  .connect-mobile-content {
    align-self: initial;
    align-items: start;
    flex: 1 0 0;
  }

  .connect-scan-container {
    align-self: start;
  }

  .connect-button-container {
    justify-content: space-between;
    padding-bottom: 4.5rem;
  }

  .connect-button-container .default-button {
    width: initial;
  }
}
