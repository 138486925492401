.diary-container {
  margin-bottom: 0;
  max-width: 100%;
}

.your-diary-icon {
  width: 18px;
  height: 20px;
}

.date-info {
  color: var(--Foundation-White-white-900, #676767);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  background-color: var(--Foundation-White-white-600, #dfdfdf);
  width: fit-content;
  display: flex;
  padding: 0px 4px;
  align-items: flex-start;
  gap: 2px;
}

.form-container {
  display: flex;
  padding: 16px 0px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.title {
  display: flex;
  padding: 8px 0px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  color: var(--Foundation-Black-black-500, #131010);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.input-title {
  border: 1px solid var(--Foundation-Black-black-50, #e7e7e7);
  background: var(--Foundation-White-white-50, #fefefe);
  display: flex;
  padding: 16px;
  font-size: 16px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  margin-bottom: 8px;
}

.input-log {
  display: flex;
  height: 248px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border: 2px solid var(--Primary-primary-400, #50d7a6);
}

.save-section {
  margin-top: 16px;
  display: flex;
  align-items: center;
  gap: 24px;
}

.photo-icon {
  width: 18px;
  height: 18px;
}

.save-btn {
  background: var(--Foundation-Black-black-500, #131010);
  color: var(--Foundation-White-white-50, #fefefe);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  padding: 12px 32px;
  gap: 8px;
  width: fit-content;
  transition: 400ms;
}

.save-btn:hover {
  cursor: pointer;
  transition: 400ms;
  background-color: var(--primary-green-500);
}

.icon-box {
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
}

.success-message {
  color: var(--Primary-primary-700, #1a9266);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.all-entries-container {
  margin-top: 24px;
}

.month-title {
  margin-top: 16px;
  color: var(--Foundation-Black-black-500, #131010);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
}

@media only screen and (max-width: 1440px) {
  .diary-container {
    margin: 0;
  }

  .save-btn {
    padding: 12px 16px;
  }
}