.walkthrough-container2 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background: var(--foundation-white-white-500);
  height: 100%;
}

.walkthrough-container-content2 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 64rem;
  background: var(--foundation-white-white-50);
  box-shadow: 0px 69px 76.6px -57px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  box-sizing: border-box;
}

@media only screen and (min-width: 768px) {
  .walkthrough-container2 {
    padding: 1.5rem;
  }
}

@media only screen and (min-width: 1920px) {
  .walkthrough-container-content2 {
    max-width: 80rem;
  }
}
