.btn {
  padding: 12px 16px;
  width: fit-content;
  background-color: black;
  color: white;
}

.save-btn-modal {
  display: flex;
  gap: 8px;
  background-color: var(--primary-green-500);
  transition: 400ms;
}

.save-btn-modal:hover {
  transition: 400ms;
  cursor: pointer;
  background-color: var(--primary-green-700);
}

.remove-btn-modal {
  display: flex;
  gap: 8px;
  transition: 400ms;
  background-color: var(--Foundation-Red-red-500, #f10400);
}

.remove-btn-modal:hover {
  transition: 400ms;
  cursor: pointer;
  background-color: var(--Foundation-Red-red-700, #ab0300);
}

.button-row {
  display: flex;
  gap: 32px;
}

.close-btn-modal {
  transition: 400ms;
}

.close-btn-modal:hover {
  transition: 400ms;
  cursor: pointer;
  background-color: var(--primary-green-500);
}

.edit-modal {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(189, 189, 189, 0.8);
}

.edit-modal-container {
  display: flex;
  flex-direction: column;
  background-color: var(--foundation-white-white-50);
}

.edit-modal-control {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: -1.5rem;
}

.edit-modal-close-icon {
  width: 1rem;
  height: 1rem;
  cursor: pointer;
}

.edit-modal-close-icon path {
  fill: var(--foundation-black-black-500);
}

.edit-modal-close-icon:hover path {
  fill: var(--primary-green-500);
}

.edit-modal-box {
  padding: 1.5rem;
  max-height: 80vh;
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.edit-modal-section {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.edit-modal-label {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
}

.allEntries-textarea {
  width: 100%;
  resize: none;
  overflow-y: hidden;
  min-height: 13rem;
  border-radius: 4px;
  border: 1px solid var(--Foundation-Black-black-50, #e7e7e7);
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  box-sizing: border-box;
  padding: 10px;
}

.allEntries-textarea:focus {
  outline: none;
}

::-webkit-resizer {
  display: none;
}

.edit-modal-section span {
  display: flex;
  padding: 8px 0px;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  color: var(--Foundation-Black-black-500, #131010);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.icon-modal {
  width: 18px;
  height: 18px;
}

@media only screen and (max-width: 767px) {
  .button-row {
    flex-wrap: wrap;
    gap: 0.5rem;
  }
}

@media only screen and (min-width: 1440px) {
  .edit-modal-box {
    padding: 3rem;
  }
}
