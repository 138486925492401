.header {
  display: flex;
  padding: 0rem;
  padding-bottom: 1.5rem;
  align-items: flex-start;
  align-content: flex-start;
  align-self: stretch;
}

.header-profile {
  display: flex;
  height: 6rem;
  width: 15rem;
  align-items: center;
  background: var(--primary-green-500);
}

.header-avatar-container {
  display: flex;
  padding: 0rem 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.header-avatar {
  width: 4rem;
  height: 4rem;
}

.header-info-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.header-info-greeting {
  color: var(--foundation-white-white-50);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.header-info-user-name {
  color: var(--foundation-white-white-50);
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
}

.header-menu-burger-container {
  display: flex;
  height: 6rem;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2rem;
  flex: 1;
  background: var(--primary-green-500);
}

.header-menu-burger {
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.header-middle-section {
  margin-left: 2rem;
  display: none;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
}

.header-add-plant-container {
  display: flex;
  padding: 0rem 1rem 0rem 1.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.header-status-inline {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.header-status-type {
  display: flex;
}

.header-status-type:nth-child(2) {
  border-left: 2px solid lightgray;
  padding-left: 1rem;
}

.header-status-container {
  display: flex;
  padding-right: 0.75rem;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.header-device-status-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex: 1 0 0;
  color: var(--foundation-black-black-500);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
}

.header-device-status-container-automation {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex: 1 0 0;
  color: var(--foundation-black-black-500);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
  border-left: 2px solid var(--foundation-black-black-50);
  padding-left: 10px;
}

.header-add-plant-button {
  display: flex;
  padding: 0.75rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border: 1px solid var(--foundation-black-black-50);
  color: var(--foundation-black-black-500);
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
  cursor: pointer;
  transition: all 0.25s;
}

.header-add-plant-button:hover {
  border: 1px solid var(--primary-green-500);
  background: var(--primary-green-500);
  color: var(--foundation-white-white-50);
}

.header-add-plant-button:hover .header-add-plant-button-icon {
  background: var(--foundation-white-white-50);
}

.header-add-plant-button:hover .header-add-plant-button-icon path {
  fill: var(--primary-green-500);
}

.header-add-plant-button-icon {
  display: flex;
  padding: 0.375rem;
  justify-content: center;
  align-items: center;
  background: var(--primary-green-500);
  transition: all 0.25s;
}

.header-device-status-text {
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  padding-right: 0.5rem;
}

.header-actions-container {
  display: none;
  padding: 0rem 2.5rem;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  flex: 1 0 0;
  align-self: stretch;
}

.header-actions-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  width: 2rem;
  height: 2rem;
  transition: all 0.25s;
  cursor: pointer;
}

.header-actions-icon:hover path {
  fill: var(--primary-green-500);
}

.header-dropdown-container {
  position: relative;
}

.header-dropdown-item-container {
  display: none;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  max-height: 0;
  background: var(--foundation-white-white-50);
  border: 1px solid var(--foundation-black-black-50);
  transition:
    max-height ease 0.75s,
    border ease 0.75s,
    background-color ease 0.75s;
  z-index: 2;
}

.header-dropdown-item {
  display: flex;
  align-items: center;
  align-self: stretch;
  text-align: left;
  color: var(--foundation-black-black-500);
  text-overflow: ellipsis;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  gap: 0.25rem;
}

.header-dropdown-item.disabled {
  color: var(--foundation-black-black-50);
}

.header-dropdown-item.disabled:hover {
  cursor: not-allowed;
  background: transparent;
}

.header-dropdown-icon-container {
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.25rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
}

.header-dropdown-icon-container.box path {
  --primary-green-500: #24cd90;
  fill: var(--primary-green-500);
}

.header-dropdown-icon-container.system path {
  fill: var(--primary-orange-500);
}

.header-dropdown-icon-container.control path {
  fill: var(--primary-blue-500);
}

.header-dropdown-icon-container.box.disabled path {
  fill: var(--foundation-black-black-50);
}

.header-dropdown-icon-container.system.disabled path {
  fill: var(--foundation-black-black-50);
}

.header-dropdown-icon-container.control.disabled path {
  fill: var(--foundation-black-black-50);
}

.header-dropdown-item:hover {
  background: var(--foundation-black-black-50);
  cursor: pointer;
}

.header-dropdown-item-container.active {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  right: -4rem;
  min-width: 12rem;
  max-height: 20rem;
  padding: 0.5rem;
}

@media only screen and (min-width: 768px) {
  .header {
    padding: 0rem;
  }

  .header-middle-section {
    display: flex;
  }

  .header-actions-container {
    display: none;
  }

  .header-menu-burger-container {
    /* marignl */
    flex: 0;
    justify-content: center;
    background: transparent;
  }

  .header-menu-burger path {
    fill: var(--foundation-black-black-500);
  }

  .header-menu-burger:hover path {
    fill: var(--primary-green-500);
  }
}

@media only screen and (min-width: 1440px) {
  .header {
    padding: 0;
  }

  .header-actions-container {
    display: flex;
  }

  .header-menu-burger-container {
    display: none;
  }
}

@media only screen and (max-width: 850px) {
  .header-status-inline div {
    font-size: 0.9rem;
  }
}
