.breadcrumbs {
  display: flex;
  justify-content: flex-start;
  padding: 1rem 0;
}

.automations-link:hover {
  cursor: pointer;
}

.stage-name-container {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
}

.automation-name-error {
  min-height: 20px;
}

.automation-name-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.icon path {
  fill: var(--foundation-white-white-50);
}

.save-btn {
  background-color: var(--primary-green-500) !important;
}

.save-btn:hover {
  background-color: var(--primary-green-700) !important;
}

.active-step {
  font-weight: bold;
}

.stage-setup-form {
  text-align: start;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  /* padding-right: 0.2rem; */
}

.stage-setup-form-error {
  color: var(--foundation-red-red-400);
  font-size: 0.9rem;
}

.stage-setup-form::-webkit-scrollbar {
  width: 7px;
}

/* Handle */
.stage-setup-form::-webkit-scrollbar-thumb {
  background: var(--primary-green-500);
  border-radius: 10px;
}

/* Handle on hover */
.stage-setup-form::-webkit-scrollbar-thumb:hover {
  background: var(--primary-green-400);
}

.label-style {
  padding: 0.5rem 0;
}

.calendar-icon path {
  fill: var(--foundation-black-black-900);
}

.date-range-picker {
  margin: 0.5rem 0px;
  padding: 0;
}

.add-interval-btn {
  display: flex;
  width: 215px;
  height: 56px;
  padding: 12px 1rem;
  margin-bottom: 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 5px;
  background-color: transparent !important;
  color: var(--Foundation-Black-black-500, #131010) !important;
  border: 1px solid var(--Foundation-Black-black-100, #b6b5b5) !important;
  transition: 400ms;
}

.add-interval-btn:hover {
  background-color: var(--primary-green-500) !important;
  color: var(--foundation-white-white-50, #fefefe) !important;
  border-color: var(--primary-green-500) !important;
  cursor: pointer;
  transition: 400ms;
}

.add-interval-icon-box {
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--primary-green-500) !important;
  transition: 400ms;
}

.add-interval-btn:hover div {
  background-color: var(--foundation-white-white-50, #fefefe) !important;
  transition: 400ms;
}

.add-interval-btn:hover .add-interval-icon path {
  fill: var(--primary-green-500) !important;
  transition: 400ms;
}

.save-btn {
  display: flex;
  align-items: center;
  background-color: var(--primary-green-500) !important;
}

.save-btn:hover {
  background-color: var(--primary-green-700) !important;
}

.inline-btns {
  display: flex;
  flex-wrap: wrap;
  padding-top: 1.5rem;
  padding-bottom: 0.75rem;
  gap: 1.5rem;
}

.inline-btns-stage-modal {
  display: flex;
  flex-wrap: wrap;
  padding-top: 1.5rem;
  padding-bottom: 0.75rem;
  gap: 1.5rem;
}

.folder-icon path {
  fill: var(--foundation-white-white-50, #fefefe);
}

.button-row {
  display: flex;
  flex-wrap: wrap;
  padding-top: 1.5rem;
  padding-bottom: 0.75rem;
  gap: 2rem;
}

.close-btn-modal {
  transition: 400ms;
}

.close-btn-modal:hover {
  transition: 400ms;
  cursor: pointer;
  background-color: var(--primary-green-500);
}

.automations-light-interval-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 1rem;
  gap: 1rem;
}

.automations-time-picker-wrapper {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex: 1;
}

.automations-remove-light-interval-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
}

.automations-remove-light-interval-icon path {
  fill: var(--foundation-black-black-500);
}

.automations-remove-light-interval-icon:hover path {
  fill: var(--foundation-red-red-400);
}

.automations-not-found-text {
  color: var(--foundation-black-black-500);
  text-align: left;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.stage-time-picker {
  flex: 1;
}

.stage-template-modal-list {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 0.2rem;
  gap: 0.5rem;
}

.stage-template-modal-list::-webkit-scrollbar {
  width: 7px;
}

/* Handle */
.stage-template-modal-list::-webkit-scrollbar-thumb {
  background: var(--primary-green-500);
  border-radius: 10px;
}

/* Handle on hover */
.stage-template-modal-list::-webkit-scrollbar-thumb:hover {
  background: var(--primary-green-400);
}

.stage-template-modal-title {
  color: var(--foundation-black-black-500);
  text-align: left;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
}

.stage-template-modal-edit-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.stage-template-modal-edit-icon path {
  fill: var(--foundation-white-white-50);
}

.stage-template-modal-check-icon {
  border: 2px solid var(--foundation-white-white-50);
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.automation-stage-temperature-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.automation-stage-temperature-container .default-input-container {
  flex: 1;
}

.MuiMultiInputDateRangeField-separator {
  display: none;
}

.load-templates-btn.default {
  background: linear-gradient(
    150deg,
    var(--foundation-black-black-900),
    var(--foundation-white-white-50),
    var(--primary-green-500)
  );
  background-size: 600% 600%;

  -webkit-animation: GradiantAnimation 2s ease infinite;
  -moz-animation: GradiantAnimation 2s ease infinite;
  animation: GradiantAnimation 2s ease infinite;
}

@media only screen and (max-width: 767px) {
  .button-row .default-button {
    flex: 1;
  }

  .footer-btns .default-button {
    flex: 1;
  }

  .date-range-picker {
    width: 100%;
  }

  .automations-stage-date-picker-container .date-range-picker.mobile {
    display: flex;
  }

  .automations-stage-date-picker-container .date-range-picker {
    display: none;
  }
}

@media only screen and (min-width: 767px) {
  .button-row {
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .automations-time-picker-wrapper {
    flex-direction: row;
  }

  .automations-stage-date-picker-container .date-range-picker.mobile {
    display: none;
  }

  .automations-stage-date-picker-container .date-range-picker {
    display: flex;
  }
}

@keyframes GradiantAnimation {
  0% {
    background-position: 0% 0%;
  }

  50% {
    background-position: 100% 100%;
  }

  100% {
    background-position: 0% 0%;
  }
}

.stage-table .stage-add-button path {
  transition: fill 0.1s;
  fill: var(--primary-green-500);
}

.stage-table .hovered:hover .stage-add-button path {
  fill: var(--foundation-white-white-50);
}

.create-stage-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.stage-table .edit-icon,
.stage-table .delete-icon {
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  height: 2rem;
  width: 2rem;
}

.stage-table .hovered:hover .delete-icon path,
.stage-table .hovered:hover .edit-icon path {
  fill: var(--foundation-White-white-50, #fefefe);
}

.hovered .delete-icon:hover {
  background-color: var(--foundation-red-red-400);
}

.stage-table .hovered:hover .edit-icon path {
  fill: var(--foundation-White-white-50, #fefefe);
}

.stage-table .hovered:hover .entry-date,
.stage-table .hovered:hover .entry-text {
  color: white;
}

.stage-table .entry-date,
.stage-table .entry-text {
  transition-duration: 0.1s;
}

.validation-message {
  color: var(--foundation-red-red-400);
  text-align: left;
  font-size: 0.85rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 600px) {
  .stage-table .entry-date {
    max-width: 120px;
    min-width: 120px;
  }
  .inline-btns-stage-modal {
    gap: 0.5rem;
  }
}

@media only screen and (max-width: 420px) {
  .button-style-mobile {
    width: 100%;
  }
}
