.body-background {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9000;
  background-color: var(--hamburger-background);
  transition: ease-in 400ms;
}

.body-background-hidden {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: transparent;
  transition: ease-out 400ms;
}

.menu-hidden {
  z-index: 9999;
  background-color: var(--foundation-white-white-50, #fefefe);
  width: 240px;
  position: absolute;
  top: 0;
  right: -100%;
  transition: right 0.4s;
}

.menu-active {
  z-index: 9999;
  background-color: var(--foundation-white-white-50, #fefefe);
  width: 240px;
  position: absolute;
  top: 0;
  right: 0;
  transition: right 0.4s;
  max-height: 100%;
  overflow: scroll;
}

.menu-header {
  padding: 1.5rem 2rem 1rem 2rem;
  display: flex;
  align-items: start;
  justify-content: space-between;
}

.menu-content {
  padding: 0 34px 32px 34px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.menu-close-icon {
  background-color: var(--foundation-black-black-900, #080707);
  padding: 4px;
}

.menu-close-icon:hover {
  cursor: pointer;
}

.close-icon {
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  background-color: var(--Blue-Progress-Bar, #508cff);
}

.menu-statuses {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  align-items: start;
}

.menu-statuses span {
  font-size: 0.85rem;
}

.menu-close-icon-blue {
  width: 12.728px;
  height: 12.728px;
}

.status-bell {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 0.85rem;
  gap: 0.7rem;
}

.remove-plant {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: 1px solid var(--Foundation-Black-black-50, #e7e7e7);
  padding: 12px 16px;
  color: var(--Foundation-Black-black-500, #131010);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.remove-plant:hover {
  cursor: pointer;
}

.buttons-list {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 0.75rem;
}

.section-title {
  color: var(--Foundation-Black-black-500, #131010);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.menu-navigation-link {
  width: 100%;
}

.menu-inline {
  display: flex;
  gap: 0.5rem;
  padding: 0.25rem;
  align-items: center;
  width: 100%;
}

.menu-inline:hover {
  background: var(--foundation-black-black-50);
  cursor: pointer;
}

.menu-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
}

.menu-icon path {
  fill: var(--Foundation-Black-black-500, #131010);
}

.menu-title {
  color: var(--Foundation-Black-black-500, #131010);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.switch-link {
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}

.switch-link:hover {
  cursor: pointer;
}

.icon-color-orange path {
  fill: var(--orange-switch-button, #fe9800);
}

.icon-color-blue path {
  fill: var(--Blue-Progress-Bar, #508cff);
}
